* {
  box-sizing: border-box;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.modal-90w {
  min-width: 70vw;
}

/*----------------------Linechart styles----------------------*/

.lineChart {
  --stroke: hsl(173 82% 46%);
  --fill: hsl(173 82% 56% / 0.2);
  --marker: #2c3e50;
  --textHeadingColor: var(--stroke);
}

.lineChart figure {
  margin: 0;
  flex: 0 1 22.3rem;
  aspect-ratio: 6 / 2;
  display: flex;
}

.lineChart svg {
  width: 100%;
  height: auto;
}

.lineChart p {
  margin: 0;
  font-size: 2rem;
}

.lineChart h3 {
  flex: 1 0 100%;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  color: #2c3e50;
}

.lineChart fieldset {
  max-width: 30rem;
  margin: 0 auto;
  border: 2px solid var(--fill);
}

.lineChart legend {
  text-align: center;
  min-width: 10rem;
  margin: 0 auto;
}

.chart-wrapper {
  width: 100%;
  max-width: 600px;
}
